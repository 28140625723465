import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <nav className="absolute flex items-center justify-between flex-wrap p-6 z-10">
    <div className="w-full block flex-grow text-sm flex-grow ">
      <Link
        to="#responsive-header"
        className="font-header font-black	uppercase inline-block mt-0 text-white mr-8"
      >
        Bier
      </Link>
      <Link
        to="#responsive-header"
        className="font-header font-black	uppercase inline-block mt-0 text-white mr-8"
      >
        Wir
      </Link>
      <Link
        to="#responsive-header"
        className="font-header font-black	uppercase inline-block mt-0 text-white mr-8"
      >
        Briefe
      </Link>
      <Link
        to="#responsive-header"
        className="font-header font-black	uppercase inline-block mt-0 text-white"
      >
        Shop
      </Link>
    </div>
  </nav>
)

export default Header
